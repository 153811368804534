import React from 'react';
import styled from 'styled-components';
import { usePaginationFetch } from '../hooks/usePaginationFetch';
import { getStories } from '../respositories/stories';
import { HomeCardTable } from '../components/HomeCardTable';
import { Loader } from '../components/Loader';
import colors from '../styles/colors';
const locale = process.env.GATSBY_LOCALE_LANGUAGE;
const containerAlias = process.env.GATSBY_BLOG_CONTAINER_ALIAS;
const containerFilter = process.env.GATSBY_BLOG_CONTAINER_FILTER;
const COVER_HEIGHT = 466.9;
const LIMIT = 9;

const getListStories = (limit) => (skip) =>
    getStories({
        limit: limit,
        skip,
        alias: containerAlias || 'join-stories-blog',
        filter: containerFilter || 'none',
    });

const Homepage = () => {
    const [storiesList, isLoading, haveMoreResult] = usePaginationFetch(getListStories(LIMIT), [
        'homepage-list',
        COVER_HEIGHT * 2,
    ]);

    return (
        <HomepageDiv>
            <HomeCardTable list={storiesList} />
            <ShowMore isLoading={isLoading} loadMoreElements={getListStories} haveMoreResult={haveMoreResult} />
        </HomepageDiv>
    );
};

const ShowMore = ({ loadMoreElements, isLoading, haveMoreResult }) =>
    haveMoreResult ? (
        <ShowMoreDiv onClick={() => !isLoading && loadMoreElements()}>
            {isLoading ? (
                <Loader />
            ) : (
                <ShowMoreSpan> {locale === 'fr_FR' ? '... Voir plus' : '... Show more'}</ShowMoreSpan>
            )}
        </ShowMoreDiv>
    ) : (
        <EmptySpaceDiv />
    );

Homepage.propTypes = {};

export default Homepage;

const HomepageDiv = styled.div``;

const ShowMoreDiv = styled.div`
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 43px;
    opacity: 0.18;
    border-radius: 6px;
    background-color: ${colors.cloudyBlue2};
    align-items: center;
    justify-content: center;
`;

const ShowMoreSpan = styled.span`
    padding: 10px;
    font-size: 1.3rem;
    color: ${colors.black};
`;

const EmptySpaceDiv = styled.div`
    display: flex;
    width: 100%;
    height: 43px;
`;
